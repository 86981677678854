.thumbnail {
  align-self: center;
  height: 25vh;
  width: auto;
}

.article-block {
  transition: all 0.2s ease-in-out;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
}

.article-block:hover {
  transform: scale(1.05);
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
