.App {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.header {
  background-color: #0000005e;
}

.sidebar {
  height: 90vh;
  width: 200px;
  position: fixed;
  top: 10vh;
  right: -200px;
  z-index: 1;
  transition: all 0.5s ease-in-out;
  color: white;
}

.sidebar-menu {
  height: 100%;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.sidebar-link {
  height: 50px;
  display: flex;
  align-items: center;
  text-align: left;
}

.sidebar-link img {
  margin-right: 5px;
}

.sidebar-button {
  display: flex;
  align-items: center;
  z-index: 1;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  color: white;
  background: none;
  border: none;
  outline: none;
  font-size: 23px;
}

.burger {
  margin-left: 10px;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: rgb(226, 226, 226);
  margin: 5px;
  transition: all 0.3s ease;
}

.burger-toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.burger-toggle .line2 {
  opacity: 0;
}

.burger-toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

.sidebar-toggle {
  transform: translateX(-200px);
}

.content {
  background-image: url('./media/background.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 100vh;
  padding-top: 12vh;
  padding-bottom: 2vh;
  transition: all 0.5s ease-in-out;
}

@media screen and (max-width: 768px) {
  .content{
    background-position: -220px;
  }
}

.content-shadow {
  filter: brightness(0.3);
}

.logo {
  font-size: 23px;
}

.logo img, .icon {
  margin-right: 5px;
}